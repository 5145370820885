import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';

export default function EditSchool() {
    const navigate = useNavigate();

    const { id } = useParams()
    console.log(id)
    const [address, setAddress] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [validationError, setValidationError] = useState({})

    useEffect(() => {
        fetchSchool()
    }, [])

    const fetchSchool = async () => {
        await axios.get(`http://localhost:8000/api/schools/${id}`).then(({ data }) => {
            console.log(data.school)
            const { name, email, phone, address } = data.school
            setName(name)
            setEmail(email)
            setPhone(phone)
            setAddress(address)
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }


    const updateSchool = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('_method', 'PATCH');
        formData.append('id', id)
        formData.append('name', name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('address', address)
        formData.append('created_by', JSON.parse(sessionStorage.currentUser).id)

        await axios.post(`http://localhost:8000/api/schools/${id}`, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            navigate("/")
        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error"
                })
            }
        })
    }

    return (
        <main role="main">
            <div className="row">
                <div className="col-sm-4"> </div>
                <div className="col-sm-4">
                    <div className="card-body">
                        <h2 className="text-center">Edit School</h2>
                        <div className="form-wrapper">
                            {
                                Object.keys(validationError).length > 0 && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="alert alert-danger">
                                                <ul className="mb-0">
                                                    {
                                                        Object.entries(validationError).map(([key, value]) => (
                                                            <li key={key}>{value}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <Form onSubmit={updateSchool}>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="Name">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" value={name} onChange={(event) => {
                                                setName(event.target.value)
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group controlId="Email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control as="input" value={email} onChange={(event) => {
                                                setEmail(event.target.value)
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <Form.Group controlId="Phone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="text" value={phone} onChange={(event) => {
                                                setPhone(event.target.value)
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="Address" className="mb-3">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control as="textarea" row={3} value={address} onChange={(event) => {
                                                setAddress(event.target.value)
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                                    Save
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4" />
            </div>
            <hr
                className="featurette-divider"
                style={{
                    marginTop: "100px"
                }}
            />
            { }

        </main>
    );
}