import React from "react";
import $rep1 from '../../images/rep1.PNG';
import $rep3 from '../../images/rep3.PNG';
import $rep8 from '../../images/rep8.PNG';
import $rep9 from '../../images/rep9.PNG';
class Reports2 extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container-fluid inner-page">
          {" "}
          <h1 className="banner-text">REPORTS</h1>
        </div>
        <div className="row padding30">
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">PRODUCTS, CLUBS, POSTS</h3>
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$rep1}
                  className="img-fluid"
                  alt=""
                  width={600}
                  height={300}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">
                  USERS DISTRIBUTION
                </h3>
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$rep3}
                  className="img-fluid"
                  alt=""
                  width={600}
                  height={300}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row padding30">
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">CLUBWISE STUDENTS DISTRIBUTION</h3>
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$rep8}
                  className="img-fluid"
                  alt=""
                  width={600}
                  height={300}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">
                  USERWISE POSTS DISTRIBUTION
                </h3>
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$rep9}
                  className="img-fluid"
                  alt=""
                  width={600}
                  height={300}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
        { }

      </main>
    );
  }
}

export default Reports2;
