import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Swal from 'sweetalert2'
import Pagination from "react-js-pagination";

export default function ListAdmins() {

  const [users, setUsers] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    fetchUsers(1)
  }, [])

  const fetchUsers = async (page) => {
    await axios.get(`http://localhost:8000/api/users/?page=${page}`).then(({ data }) => {
      setUsers(data.data)
      setData(data)
    })
  }

  const deleteUser = async (id) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      return result.isConfirmed
    });

    if (!isConfirm) {
      return;
    }

    await axios.delete(`http://localhost:8000/api/users/${id}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      })
      fetchUsers()
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }

  return (
    <div className="container">
      <div className="row">
        <div className='col-12'>
          <Link className='btn btn-primary mb-2 float-end' to={"/add/user"}>
            Create User
          </Link>
        </div>
        <div className="col-12">
          <div className="card card-body">
            <div className="table-responsive">
              <table className="table table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Photo</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    users.length > 0 && (
                      users.map((row, key) => (
                        <tr key={key}>
                          <td>{row.name}</td>
                          <td>{row.email}</td>
                          <td>{row.role ? row.role : 'Student'}</td>
                          <td>
                            <img width="50px" src={`http://localhost:8000/storage/user/picture/${row.picture}`} alt={row.picture ? row.name : 'No Photo'} />
                          </td>
                          <td>
                            <Link to={`/edit/user/${row.id}`} className='btn btn-success me-2'>
                              Edit
                            </Link>
                            <Button variant="danger" onClick={() => deleteUser(row.id)}>
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <nav
          aria-label="..."
          style={{
            margin: "0 auto"
          }}
        ><Pagination
            activePage={data.current_page ? data.current_page : 1}
            itemsCountPerPage={data.per_page ? data.per_page : 10}
            totalItemsCount={data.total ? data.total : 0}
            onChange={(pageNumber) => {
              fetchUsers(pageNumber)
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="«"
            lastPageText="»"
          />
        </nav>
      </div>
    </div>
  )
}