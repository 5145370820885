import React, { useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'

export default function Contact() {
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [validationError, setValidationError] = useState({})


  const createEnquiry = async (e) => {
    e.preventDefault();

    const formData = new FormData()

    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('message', message)
    console.log(`message; ${message}`);

    await axios.post(`http://localhost:8000/api/enquires`, formData).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      })
      navigate("/")
    }).catch(({ response }) => {
      if (response.status === 422) {
        setValidationError(response.data.errors)
      } else {
        Swal.fire({
          text: response.data.message,
          icon: "error"
        })
      }
    })
  }

  return (
    <main role="main">
      <div className="container-fluid inner-page">
        {" "}
        <h1 className="banner-text">Contact Us</h1>
      </div>
      <div className="container page-top-2">
        <div className="row">
          <div className="col-sm-6">
            <div className="card bdr-none">
              <h2 className="page-heading">Email us at:</h2>
              <p>
                <a href="mailto://marketplace@gmail.com">
                  marketplace@gmail.com
                </a>
              </p>
              <h2 className="page-heading">Reach out us at:</h2>
              <p className="lead">
                University of Texas at Arlington, <br />
                701 S Neddermann Drive, <br />
                Arlington,Texas
                <br /> USA, 76019{" "}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-wrapper">
              {
                Object.keys(validationError).length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <ul className="mb-0">
                          {
                            Object.entries(validationError).map(([key, value]) => (
                              <li key={key}>{value}</li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              }

              <Form onSubmit={createEnquiry}>
                <Row>
                  <Col>
                    <Form.Group controlId="Name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" value={name} onChange={(event) => {
                        setName(event.target.value)
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="Email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control as="input" value={email} onChange={(event) => {
                        setEmail(event.target.value)
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="Phone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control type="number" value={phone} onChange={(event) => {
                        setPhone(event.target.value)
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="Message">
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={3} value={message} onChange={(event) => {
                        setMessage(event.target.value)
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <hr className="featurette-divider" />
        <div className="row">
          <div className="col-sm-12">
            <div className="card bdr-radius">
              <iframe title="Map Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3356.3755882236983!2d-97.11738578556235!3d32.729216193729776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e7d6dabc9564d%3A0x6c5cbeb084c8b76a!2sUniversity%20of%20Texas%20at%20Arlington!5e0!3m2!1sen!2sus!4v1664832847853!5m2!1sen!2sus"
                width="100%"
                height={450}
                style={{
                  border: 0
                }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
      </div>
      { }

    </main>
  );
}
