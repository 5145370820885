import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from '../../images/logo.png';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import { ROLE } from "../../services/role";
export default function Header() {
  const navigate = useNavigate();

  const [logPath, setLogPath] = useState("")
  const [role, setRole] = useState("")
  const [logPathName, setLogPathName] = useState("")

  useEffect(() => {
    setLogPath(sessionStorage.getItem("logPath"))
    setLogPathName(sessionStorage.getItem("logPathName"))
    let currentUser = null;
    if (sessionStorage.currentUser != null && sessionStorage.currentUser != undefined && sessionStorage.currentUser != 'undefined') {
      currentUser = JSON.parse(sessionStorage.currentUser);
      setRole(currentUser.role)
    }
  }, [])

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-primary">
        <div className="container-fluid">
          <Link className="navbar-brand" to={"/"}>
            <img src={logo} height="50px" width="80px" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mr-auto ">
              <li className="nav-item active">
                <Link className="nav-link active" to={"/"}>
                  HOME <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/about"}>
                  ABOUT
                </Link>
              </li>

              <li className="nav-item ">
                <Link className="nav-link" to={"/services"}>
                  SERVICES
                </Link></li>

              <li className="nav-item ">
                <Link className="nav-link" to={"/blog"}>
                  POSTS
                </Link></li>

              {role === ROLE.STUDENT && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/clubs"}>
                    CLUBS
                  </Link></li>
              )}
              {role === ROLE.SCHOOL_ADMIN && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/manage/clubs"}>
                    CLUBS
                  </Link></li>
              )}

              {role === ROLE.BUSINESS_OWNER && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/manage/advertisments"}>
                    ADVERTISEMENTS
                  </Link></li>
              )}
              {role === ROLE.STUDENT && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/products"}>
                    PRODUCTS
                  </Link></li>
              )}
              {role === ROLE.BUSINESS_OWNER && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/manage/products"}>
                    PRODUCTS
                  </Link></li>
              )}
              {role === ROLE.SCHOOL_ADMIN && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/manage/students"}>
                    MANAGE STUDENTS
                  </Link></li>
              )}
              {role === ROLE.SCHOOL_ADMIN && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/manage/owners"}>
                    MANAGE OWNERS
                  </Link></li>
              )}
              {role === ROLE.SUPER_ADMIN && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/manage/users"}>
                    MANAGE USERS
                  </Link></li>
              )}
              {role === ROLE.SUPER_ADMIN && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/manage/schools"}>
                    MANAGE SCHOOLS
                  </Link></li>
              )}{role === ROLE.SCHOOL_ADMIN && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/reports2"}>
                    REPORTS
                  </Link></li>
              )}
              {role === ROLE.SUPER_ADMIN && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/reports"}>
                    REPORTS
                  </Link></li>
              )}
              {role === ROLE.STUDENT && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/chat1"}>
                    CHAT
                  </Link></li>
              )}
              {role === ROLE.BUSINESS_OWNER && (
                <li className="nav-item ">
                  <Link className="nav-link" to={"/chat2"}>
                    CHAT
                  </Link></li>
              )}

              <li className="nav-item">
                <a className="nav-link" target="_blank" href="http://cxy3402.uta.cloud/blog/">BLOG</a>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to={"/contact"}>
                  CONTACT
                </Link>
              </li>
            </ul>
            <div className="form-inline my-2 my-lg-0">
              <ul className="navbar-nav mr-auto ">

                {role == ROLE.STUDENT && (<li className="nav-item">
                  <Link className="nav-link" to={"/view/profile/" + JSON.parse(sessionStorage.currentUser).id}>
                    Profile
                  </Link>
                </li>)
                }
                <li className="nav-item active">
                  {
                    <Link className="nav-link" to={logPath ? logPath : "/user/login"}>
                      {logPathName ? logPathName : 'Login'}
                    </Link>
                  } </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
} 
