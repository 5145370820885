
import React from 'react';
import './App.css';
import './css/style.css';
import './css/style2.css';
import './css/carousel.css'; 
import Header from './components/static/Header';
import Home from './components/static/Home';
import Footer from './components/static/Footer';
import About from './components/static/About';
import Reports from './components/static/Reports';
import Reports2 from './components/static/Reports2';
import Chat1 from './components/static/Chat1';
import Chat2 from './components/static/Chat2';
import Dashboard from './components/static/Dashboard';
import Contact from './components/static/Contact';
import EditClub from './components/static/EditClub';
import EditProduct from './components/static/EditProduct';
import EditUser from './components/static/EditUser';
import EditAdvertisment from './components/static/EditAdvertisment';
import ListServices from './components/static/ListServices';
import ListClubs from './components/static/ListClubs';
import ListAdvertisments from './components/static/ListAdvertisments';
import ListProducts from './components/static/ListProducts';
import ManageClubs from './components/static/ManageClubs';
import ManageAdvertisments from './components/static/ManageAdvertisments';
import ManageProducts from './components/static/ManageProducts';
import AddProduct from './components/static/AddProduct';
import AddAdvertisment from './components/static/AddAdvertisment';
import AddClub from './components/static/AddClub';
import EditProfile from './components/static/EditProfile';
import DeleteProduct from './components/static/DeleteProduct';
import DeleteAdvertisment from './components/static/DeleteAdvertisment';
import DeleteClub from './components/static/DeleteClub';
import Login from './components/static/Login';
import Register from './components/static/Register';
import Register2 from './components/static/Register2';
import Profile from './components/static/Profile';
import ManageUsers from './components/static/ManageUsers';
import ForgetPassword from './components/static/ForgetPassword';
import ListUsers from './components/static/ListUsers';
import AddUser from './components/static/AddUser';
import ViewAdvertisment from './components/static/ViewAdvertisment';
import ViewClub from './components/static/ViewClub';
import ViewProduct from './components/static/ViewProduct';
import ViewPost from './components/static/ViewPost';
import AddPost from './components/static/AddPost';
import ListPosts from './components/static/ListPosts';
import EditPost from './components/static/EditPost';
import ManagePosts from './components/static/ManagePosts';
import ViewUser from './components/static/ViewUser';
import Logout from './components/static/Logout';
import Error403 from './components/static/Error403';
import { PrivateRoute } from './routes/PrivateRoute'; 
import {ROLE} from './services/role';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import AddStudent from './components/static/AddStudent';
import AddBussinessOwner from './components/static/AddBussinessOwner';
import ListStudents from './components/static/ListStudents';
import ListOwners from './components/static/ListOwners';
import ListAdmins from './components/static/ListAdmins';
import ListSchools from './components/static/ListSchools';
import AddSchool from './components/static/AddSchool';
import EditSchool from './components/static/EditSchool';
function App() {
  return (<>
    <div className="App">
      <Router>
        <Header />
        <div> <Routes>
          {/* <Route path="/" element={<PrivateRoute roles={['STUDENT']} component={Home} />} /> */}
         <Route path="/" element={<Home/>} />
         <Route path="/home" element={<Home/>} />
          <Route path="/about" element={<About />} />
          <Route path="/chat1" element={<PrivateRoute roles={[ROLE.STUDENT]} component={Chat1} />} />
          <Route path="/chat2" element={<PrivateRoute roles={[ROLE.BUSINESS_OWNER]} component={Chat2} />} />
          <Route path="/reports" element={<PrivateRoute roles={[ROLE.SUPER_ADMIN]} component={Reports} />}/>
          <Route path="/reports2" element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN]} component={Reports2} />}/>
          <Route path="/blog" element={<ListPosts />} />
          <Route path="/dashboard" element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={Dashboard} />}/>
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<ListServices />} />
          <Route path="/clubs" element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={ListClubs} />} />
          <Route path="/advertisments" element={<PrivateRoute roles={[ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={ListAdvertisments} />} />
          <Route path="/products"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={ListProducts}  />} />
          <Route path="/posts"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={ListPosts}  />} />
          <Route path="/manage/clubs" element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={ManageClubs} />} />
          <Route path="/manage/advertisments" element={<PrivateRoute roles={[ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={ManageAdvertisments} />} />
          <Route path="/manage/products"  element={<PrivateRoute roles={[ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={ManageProducts}  />} />
          <Route path="/manage/owners"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={ListOwners}  />} />
          <Route path="/manage/students"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={ListStudents}  />} />
          <Route path="/manage/users"  element={<PrivateRoute roles={[ ROLE.SUPER_ADMIN]} component={ListAdmins}  />} />
          <Route path="/manage/schools"  element={<PrivateRoute roles={[ ROLE.SUPER_ADMIN]} component={ListSchools}  />} />
          <Route path="/add/product"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={AddProduct}  />}  />
           <Route path="/add/post"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={AddPost}  />}  />
          <Route path="/add/advertisment"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={AddAdvertisment}  />} />
          <Route path="/add/club"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={AddClub}  />}  />
           <Route path="/add/school"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={AddSchool}  />}  />
          <Route path="/delete/product"  element={<PrivateRoute roles={[ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={DeleteProduct}  />} />
          <Route path="/delete/advertisment"  element={<PrivateRoute roles={[ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={DeleteAdvertisment}  />}  />
          <Route path="/delete/club"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={DeleteClub}  />}  />
          <Route path="/user/login" element={<Login />} />
          <Route path="/register2" element={<Register2 />} />
          <Route path="/user/register"  element={<PrivateRoute roles={[ ROLE.SUPER_ADMIN]} component={Register}  />}  />
          <Route path="/view/profile/:profileId"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={Profile}  />}  />
          <Route path="/admin/users"  element={<PrivateRoute roles={[ ROLE.SUPER_ADMIN]} component={ManageUsers}  />}  />
          <Route path="/user/forgetpassword" element={<ForgetPassword />} />
          <Route exact path="/edit/club/:id"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={EditClub}  />}  />
          <Route exact path="/edit/product/:id"  element={<PrivateRoute roles={[ROLE.STUDENT, ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={EditProduct}  />}  />
          <Route exact path="/edit/user/:id"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={EditUser}  />}  />
          <Route exact path="/edit/advertisment/:id"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={EditAdvertisment}  />}  />
          <Route exact path="/edit/school/:id"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={EditSchool}  />}  />
          <Route exact path="/edit/profile"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={EditProfile}  />}  />
           <Route exact path="/edit/post/:id"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={EditPost}  />}  />
          <Route exact path="/view/advertisment/:id"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={ViewAdvertisment}  />} />
          <Route exact path="/view/post/:id"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={ViewPost}  />} />
          <Route exact path="/view/club/:id"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={ViewClub}  />}  />
          <Route exact path="/view/product/:id"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={ViewProduct}  />} />
          <Route exact path="/view/user/:id"  element={<PrivateRoute roles={[ROLE.STUDENT,ROLE.SCHOOL_ADMIN,ROLE.BUSINESS_OWNER, ROLE.SUPER_ADMIN]} component={ViewUser}  />}  />
          <Route exact path="/view/users"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={ListUsers}  />}  />
          <Route exact path="/add/student"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={AddStudent}  />}  />
          <Route exact path="/add/businessowner"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={AddBussinessOwner}  />}  />
          <Route exact path="/add/user"  element={<PrivateRoute roles={[ROLE.SCHOOL_ADMIN, ROLE.SUPER_ADMIN]} component={AddUser}  />}  />
          <Route exact path="/user/logout" element={<Logout />} />
          <Route exact path="/403" element={<Error403 />} />
        </Routes>
        </div>
      </Router>
      <Footer />
    </div></>
  );
}

export default App;
