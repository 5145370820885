import React from "react";
import { Link } from "react-router-dom";

class ManageUsers extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container-fluid inner-page">
          {" "}
          <h1 className="banner-text">SERVICES</h1>
        </div>
        <div className="container page-top-2">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="FL page-heading">Manage Users</h1>
            </div>
            <div className="col-sm-4">
              {" "}
              <Link className="btn btn-primary FR margin6" to={"/add/user"}>
                Add User
              </Link>
              <Link className="btn btn-primary FR margin6" to={"/delete/user"}>
                Delete User
              </Link>
            </div>
          </div>
          <div className="row padding30">
            <table>
              <tbody>
                <tr>
                  <th>UserName</th>
                  <th>Detail1</th>
                  <th>Detail2</th>
                </tr>
                <tr>
                  <td>User1</td>
                  <td>Maria Anders</td>
                  <td>Germany</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <nav
              aria-label="..."
              style={{
                margin: "0 auto"
              }}
            >
              <ul className="pagination">
                <li className="page-item">
                  <Link className="page-link" to={"/admin/users"}>
                    <span aria-hidden="true">«</span>
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/admin/users"}>
                    1
                  </Link>
                </li>
                <li className="page-item active" aria-current="page">
                  <Link className="page-link" to={"/admin/users"}>
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/admin/users"}>
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/admin/users"}>
                    4
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/admin/users"}>
                    ...
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/admin/users"}>
                    7
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/admin/users"}>
                    8
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/admin/users"}>
                    <span aria-hidden="true">»</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <hr
            className="featurette-divider"
            style={{
              marginTop: "100px"
            }}
          />
        </div>
        { }

      </main>
    );
  }
}

export default ManageUsers;
