import React, { useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import emailjs from 'emailjs-com';

export default function Register2() {
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [role, setRole] = useState("STUDENT")
  const [image, setImage] = useState()
  const [validationError, setValidationError] = useState({})

  const changeHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const createUser = async (e) => {
    e.preventDefault();

    emailjs.sendForm('service_h87y607', 'template_ujfvl7f', e.target, 'UGCZJqcHAgdMrwUPu')
      .then((result) => {
        //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
          console.log(error.text);
      });

    const formData = new FormData()

    formData.append('name', name)
    formData.append('email', email)
    formData.append('password', password)
    formData.append('role', role)
    formData.append('created_by', 1)
    if (image) {
      formData.append('picture', image)
    }

    await axios.post(`http://localhost:8000/api/users`, formData).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: "Registered Successfully. Please login."
      })
      navigate("/")
    }).catch(({ response }) => {
      if (response.status === 422) {
        setValidationError(response.data.errors)
      } else {
        Swal.fire({
          text: response.data.message,
          icon: "error"
        })
      }
    })
    




  }

  return (
    <main role="main">
      <div className="row">
        <div className="col-sm-4"> </div>
        <div className="col-sm-4">
          <div className="card-body">
            <h2 className="text-center">Register</h2>
            <p className="text-center">
                  Already Registered? <Link to={"/user/login"}>Login</Link>
                </p>
            <div className="form-wrapper">
              {
                Object.keys(validationError).length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <ul className="mb-0">
                          {
                            Object.entries(validationError).map(([key, value]) => (
                              <li key={key}>{value}</li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              }
              <Form onSubmit={createUser}>
                <Row>
                  <Col>
                    <Form.Group controlId="Name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" required value={name} onChange={(event) => {
                        setName(event.target.value)
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="Email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control as="input" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required value={email} name="to_email" onChange={(event) => {
                        setEmail(event.target.value)
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="Password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" title="Minimum Length:8. Must contain one uppecase,lowercase,numeric." pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" value={password} onChange={(event) => {
                        setPassword(event.target.value)
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="Image" className="mb-3">
                      <Form.Label>Image</Form.Label>
                      <Form.Control type="file" required onChange={changeHandler} />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                  Register
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-sm-4" />
      </div>
      <hr
        className="featurette-divider"
        style={{
          marginTop: "100px"
        }}
      />
      { }

    </main>
  );
}
