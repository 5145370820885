import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="container">
        <p className="float-right">
          <a href={`${window.location.pathname}`}>Back to top</a>
        </p>
        <p>
          © 2022 Student Market Shire
        </p>
      </footer>
    );
  }
}

export default Footer;
