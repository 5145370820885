import React, { useEffect, useState } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';

import { useNavigate, useParams } from 'react-router-dom'
export default function Error403() {

    return (
        <main role="main">
            <div className="container-fluid inner-page">
                {" "}
                <h1 className="banner-text">Access Denied.</h1>
            </div>

        </main>
    );


}

