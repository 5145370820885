import React, { useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'

export default function AddPost() {
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [url, setUrl] = useState("")
  const [image, setImage] = useState()
  const [validationError, setValidationError] = useState({})

  const changeHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const createProduct = async (e) => {
    e.preventDefault();
    let currentUser = null;
    if (sessionStorage.currentUser) {
      currentUser = JSON.parse(sessionStorage.currentUser);
    }

    const formData = new FormData()

    formData.append('name', name)
    formData.append('description', description)
    formData.append('url', url)
    formData.append('picture', image)
    formData.append('created_by', currentUser.id)

    await axios.post(`http://localhost:8000/api/posts`, formData).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      })
      navigate("/")
    }).catch(({ response }) => {
      if (response.status === 422) {
        setValidationError(response.data.errors)
      } else {
        Swal.fire({
          text: response.data.message,
          icon: "error"
        })
      }
    })
  }

  return (
    <main role="main">
      <div className="row">
        <div className="col-sm-4"> </div>
        <div className="col-sm-4">
          <div className="card-body">
            <h2 className="text-center">Add Post</h2>
            <div className="form-wrapper">
              {
                Object.keys(validationError).length > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-danger">
                        <ul className="mb-0">
                          {
                            Object.entries(validationError).map(([key, value]) => (
                              <li key={key}>{value}</li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              }
              <Form onSubmit={createProduct}>
                <Row>
                  <Col>
                    <Form.Group controlId="Name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" value={name} onChange={(event) => {
                        setName(event.target.value)
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="Description">
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea" rows={3} value={description} onChange={(event) => {
                        setDescription(event.target.value)
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <Form.Group controlId="Url">
                      <Form.Label>Url</Form.Label>
                      <Form.Control type="text" value={url} onChange={(event) => {
                        setUrl(event.target.value)
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="Image" className="mb-3">
                      <Form.Label>Image</Form.Label>
                      <Form.Control type="file" onChange={changeHandler} />
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-sm-4" />
      </div>
      <hr
        className="featurette-divider"
        style={{
          marginTop: "100px"
        }}
      />
      { }

    </main>
  );
}
