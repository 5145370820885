import React from "react";
import $1 from '../../images/1.png';
class About extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container-fluid inner-page">
          {" "}
          <h1 className="banner-text">About Us</h1>
        </div>
        <div className="row padding30">
          <div className="col-sm-8">
            <div className="card bdr-none">
              <div className="card-body">
                <h2 className="page-heading">Who we are?</h2>
                <p className="lead">
                  With an objective of helping students retrieve their
                  requirements at an easier pace, help them unravel their
                  interests and add skills to their repertoire by getting
                  associated with the student clubs, to help the business owners
                  stretch their market proximity in the college, we've come up
                  with a website that helps both students and the business
                  owners. We as a team first require the college to register
                  with us and get our services offered.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card bdr-radius">
              <img src={$1} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
      </main>
    );
  }
}

export default About;
