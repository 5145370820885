import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";

export default function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [validationError, setValidationError] = useState({})

    useEffect(() => {

    }, [])


    const doLogin = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('email', email)
        formData.append('password', password)

        await axios.post(`http://127.0.0.1:8000/api/login`, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            }).then(function () {
                sessionStorage.setItem("isUserLoggedIn", true);
                sessionStorage.setItem("logPath", "/user/logout");
                sessionStorage.setItem("logPathName", "Logout");
                window.location.href = "/";
            });
            sessionStorage.setItem("currentUser", JSON.stringify(data.data))
            const mydata = JSON.parse(sessionStorage.getItem("currentUser"))
            console.log(mydata.id)


        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error"
                })
            }
        })
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">LOGIN</h4>
                            <p className="text-center">Not Registered yet? <Link to={"/register2"}>Register</Link></p>
                            <hr />
                            <div className="form-wrapper">
                                {
                                    Object.keys(validationError).length > 0 && (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert alert-danger">
                                                    <ul className="mb-0">
                                                        {
                                                            Object.entries(validationError).map(([key, value]) => (
                                                                <li key={key}>{value}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <Form onSubmit={doLogin}>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="Email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" value={email} required onChange={(event) => {
                                                    setEmail(event.target.value)
                                                }} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Group controlId="Password">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password" title="Minimum Length:8. Must contain one uppecase,lowercase,numeric." pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" value={password} required onChange={(event) => {
                                                    setPassword(event.target.value)
                                                }} />
                                                <p className="text-center">Forgot Password? <Link to={"/user/forgetpassword"}>Reset Password</Link></p>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                                        Sign In
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}