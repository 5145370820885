import React from "react";

class ForgetPassword extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container page-top">
          <div className="row">
            <div className="col-sm-4"> </div>
            <div className="col-sm-4 card box-shadow">
              <div className="card-body">
                <h2 className="text-center">Forget Password</h2>
                <p className="text-center">New Password</p>
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlSelect2">
                      Security Question
                    </label>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect2"
                    >
                      <option>When is your date of birth?</option>
                      <option>What is your memorable date?</option>
                      <option>What is your favourite movie?</option>
                      <option>What is your favourite cricket team?</option>
                      <option>
                        What is the make and model of your first car?
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="answer">Security Answer</label>
                    <input
                      type="text"
                      className="form-control"
                      id="answer"
                      placeholder="answer"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="email@email.com"
                      required
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="btn btn-primary">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-sm-4" />
          </div>
          <hr
            className="featurette-divider"
            style={{
              marginTop: "100px"
            }}
          />
        </div>
        { }

      </main>
    );
  }
}

export default ForgetPassword;
