import $5 from '../../images/5.jpg';
import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';

export default function ViewUser() {
  const navigate = useNavigate();

  const { id } = useParams()
  console.log(id)
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [url, setUrl] = useState("")
  const [image, setImage] = useState()
  const [validationError, setValidationError] = useState({})

  useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = async () => {
    await axios.get(`http://localhost:8000/api/users/${id}`).then(({ data }) => {
      console.log(data.user)
      const { name, description, url, picture } = data.user
      setName(name)
      setDescription(description)
      setUrl(url)
      setImage(picture)
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }



  return (
    <main role="main">
      <div className="container-fluid inner-page">
        {" "}
        <h1 className="banner-text">ADVERTISEMENT VIEW</h1>
      </div>
      <div className="container page-top-2">
        <div className="row padding30">

          <div className="col-sm-5">
            <img src={`http://localhost:8000/storage/user/picture/${image}`} alt={image ? name : 'No Photo'} />

          </div>
          <div className="col-sm-7">
            <h1>
              Name : <span className="color-one">{name}</span>{" "}
            </h1>
            <h2>
              URL for Reference :{url}
            </h2>
            <h2>Description : </h2>
            <p className="color-one lead">
              {description}
            </p>
            { }
          </div>
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
      </div>
      { }

    </main>
  );

}

