import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import Pagination from "react-js-pagination";

export default function ListAdvertisments() {

  const [advertisments, setAdvertisments] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    fetchAdvertisments(1)
  }, [])

  const fetchAdvertisments = async (page) => {
    await axios.get(`http://localhost:8000/api/advertisments/?page=${page}`).then(({ data }) => {
      setAdvertisments(data.data)
      setData(data)

    })
  }

  const deleteAdvertisment = async (id) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      return result.isConfirmed
    });

    if (!isConfirm) {
      return;
    }

    await axios.delete(`http://localhost:8000/api/advertisments/${id}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      })
      fetchAdvertisments()
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }
  const editAdvertisment = async (id) => {
    window.location.href = "edit/advertisment/" + id;
  }
  const viewAdvertisment = async (id) => {
    window.location.href = "view/advertisment/" + id;

  }

  return (
    <main role="main">
      <div className="container-fluid inner-page">
        {" "}
        <h1 className="banner-text">ADVERTISEMENTS</h1>
      </div>
      <div className="container page-top-2">
        <div className="row">
          <div className="col-sm-8">
            <h1 className="FL page-heading">ADVERTISEMENTS</h1>
          </div>
          <div className="col-sm-4">
            {" "}
            <Link
              className="btn btn-primary FR margin6"
              to={"/add/advertisment"}
            >
              Add Advertisement
            </Link>
            {/* <Link
              className="btn btn-primary FR margin6"
              to={"/delete/advertisment"}
            >
              Delete Adds
             </Link>*/
            }
          </div>
        </div>
        <div className="row padding30">
          {
            advertisments.length > 0 && (
              advertisments.map((row, key) => (
                <div className="col-sm-3" key={key}>
                  <div className="card bdr-radius">
                    <img src={`http://localhost:8000/storage/advertisment/picture/${row.picture}`} alt={row.picture ? row.name : 'No Photo'} />

                  </div>
                  <div className="club-title">
                    <Link to={"/view/advertisment/" + row.id}>{row.name}</Link>
                  </div>
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-primary btn-sm active">
                      <input type="radio" name="options" id="option1" onClick={() => viewAdvertisment(row.id)} autocomplete="off" checked /> View
                    </label>
                    <label class="btn btn-info btn-sm">
                      <input type="radio" name="options" id="option2" onClick={() => editAdvertisment(row.id)} autocomplete="off" /> Edit
                    </label>
                    <label class="btn btn-danger btn-sm">
                      <input type="radio" name="options" id="option3" onClick={() => deleteAdvertisment(row.id)} autocomplete="off" /> Delete
                    </label>
                  </div>
                </div>
              )
              )
            )
          }
        </div>
        <div className="row">
          <nav
            aria-label="..."
            style={{
              margin: "0 auto"
            }}
          >
            <Pagination
              activePage={data.current_page ? data.current_page : 1}
              itemsCountPerPage={data.per_page ? data.per_page : 10}
              totalItemsCount={data.total ? data.total : 0}
              onChange={(pageNumber) => {
                fetchAdvertisments(pageNumber)
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="«"
              lastPageText="»"
            />
          </nav>
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
      </div>
      { }

    </main>
  );
}  
