import React from "react";
import $9 from '../../images/9.jpg';
import $10 from '../../images/10.jpg';

class ListServices extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container-fluid inner-page">
          {" "}
          <h1 className="banner-text">SERVICES</h1>
        </div>
        <div className="row padding30">
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-primary">MARKET PRODUCTS</h3>
                <p className="card-text mb-auto">
                  Our website enables students and business owners to market the
                  products by posting them on the website.
                </p>
                { }
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$9}
                  className="img-fluid"
                  alt=""
                  width={200}
                  height={250}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">
                  ASSOCIATE WITH STUDENT CLUBS
                </h3>
                <p className="mb-auto">
                  Our website enables students to get associated with all the
                  student clubs in the college by posting them on our website.
                </p>
                { }
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$10}
                  className="img-fluid"
                  alt=""
                  width={200}
                  height={250}
                />
              </div>
            </div>
          </div>
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
        { }

      </main>
    );
  }
}

export default ListServices;
