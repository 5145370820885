import React from "react";
import $rep1 from '../../images/rep1.PNG';
import $rep2 from '../../images/rep2.PNG';
import $rep4 from '../../images/rep4.PNG';
import $rep5 from '../../images/rep5.PNG';
import $rep6 from '../../images/rep6.PNG';
import $rep7 from '../../images/rep7.PNG';
class Reports extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container-fluid inner-page">
          {" "}
          <h1 className="banner-text">REPORTS</h1>
        </div>
        <div className="row padding30">
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">PRODUCTS, CLUBS, POSTS</h3>
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$rep1}
                  className="img-fluid"
                  alt=""
                  width={600}
                  height={300}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">
                  USERS DISTRIBUTION
                </h3>
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$rep2}
                  className="img-fluid"
                  alt=""
                  width={600}
                  height={300}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row padding30">
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">SCHOOLWISE DISTRIBUTION OF CLUBS</h3>
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$rep4}
                  className="img-fluid"
                  alt=""
                  width={600}
                  height={300}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">
                  SCHOOLWISE SALES
                </h3>
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$rep5}
                  className="img-fluid"
                  alt=""
                  width={600}
                  height={300}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row padding30">
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">SCHOOLWISE DISTRIBUTION OF STUDENTS</h3>
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$rep6}
                  className="img-fluid"
                  alt=""
                  width={600}
                  height={300}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <h3 className="mb-0 text-success">
                  SCHOOLWISE POSTS
                </h3>
              </div>
              <div className="col-auto d-none d-lg-block">
                { }
                <img
                  src={$rep7}
                  className="img-fluid"
                  alt=""
                  width={600}
                  height={300}
                  style={{
                    height: "255px"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
        { }

      </main>
    );
  }
}

export default Reports;
