import React from "react";
import { Link } from "react-router-dom";
import $11 from '../../images/11.webp';

import $cart4 from '../../images/cart4.svg';
class DeleteProduct extends React.Component {
  render() {
    return (
      <main role="main">
        <div className="container-fluid inner-page">
          {" "}
          <h1 className="banner-text">PRODUCTS</h1>
        </div>
        <div className="container page-top-2">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="FL page-heading">PRODUCTS</h1>
            </div>
            <div className="col-sm-4">
              {" "}
              <Link
                className="btn btn-primary FR"
                to={"/delete/product"}
                onclick="alert('Selected items are deleted')"
              >
                Delete
              </Link>
            </div>
          </div>
          <div className="row padding30">
            <div className="col-sm-3 margin-bottom10 ">
              <div className="card bdr-radius">
                <img src={$11} className="img-fluid" alt="" />
              </div>
              <div className="club-title">
                <Link to={"/delete/product"}>PRODUCT - 1</Link>
              </div>
              <div className="price-cart">
                <span className="prcing">199$</span>
                <span className="carting">
                  {" "}
                  <img src={$cart4} alt="" />
                </span>
              </div>
              <br />
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Check me to Delete
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <nav
              aria-label="..."
              style={{
                margin: "0 auto"
              }}
            >
              <ul className="pagination">
                <li className="page-item">
                  <Link className="page-link" to={"/delete/product"}>
                    <span aria-hidden="true">«</span>
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/product"}>
                    1
                  </Link>
                </li>
                <li className="page-item active" aria-current="page">
                  <Link className="page-link" to={"/delete/product"}>
                    2
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/product"}>
                    3
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/product"}>
                    4
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/product"}>
                    5
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/product"}>
                    6
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/product"}>
                    ...
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/product"}>
                    15
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/product"}>
                    16
                  </Link>
                </li>
                <li className="page-item">
                  <Link className="page-link" to={"/delete/product"}>
                    <span aria-hidden="true">»</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <hr
            className="featurette-divider"
            style={{
              marginTop: "100px"
            }}
          />
        </div>
        { }

      </main>
    );
  }
}

export default DeleteProduct;
