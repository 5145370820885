import $5 from '../../images/5.jpg';
import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import { event } from 'jquery';

export default function ViewProduct() {
  const navigate = useNavigate();

  const { id } = useParams()
  console.log(id)
  const [orderId, setOrderId] = useState("")
  const [isOrdered, setIsOrdered] = useState("")
  const [name, setName] = useState("")
  const [price, setPrice] = useState("")
  const [description, setDescription] = useState("")
  const [count, setCount] = useState("")
  const [url, setUrl] = useState("")
  const [image, setImage] = useState()
  useEffect(() => {
    fetchProduct()
  }, [])

  const createOrder = async (e) => {
    e.preventDefault();

    const formData = new FormData()

    formData.append('product_id', id)
    formData.append('student_id', JSON.parse(sessionStorage.currentUser).id)

    await axios.post(`http://localhost:8000/api/orders`, formData).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: "Order Successful"
      }).then(() => {
        window.location.reload();;
      })
    }).catch(({ response }) => {
      Swal.fire({
        text: response.data.message,
        icon: "error"
      })

    })
  }
  const cancelOrder = async (e) => {
    e.preventDefault();

    await axios.delete(`http://localhost:8000/api/orders/${orderId}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: "Order Cancelled and Product returned to warehouse"
      }).then(() => {
        window.location.reload();;
      })
    }).catch(({ response }) => {
      Swal.fire({
        text: response.data.message,
        icon: "error"
      })

    })
  }
  const fetchProduct = async () => {
    await axios.get(`http://localhost:8000/api/products/${id}`).then(({ data }) => {
      console.log(data.product)
      const { name, price, description, count, url, picture } = data.product
      setName(name)
      setDescription(description)
      setUrl(url)
      setPrice(price)
      setCount(count)
      setImage(picture)
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
    await axios.get(`http://localhost:8000/api/orders?product_id=${id}&student_id=${JSON.parse(sessionStorage.currentUser).id}`).then(({ data }) => {
      if (data.length > 0) {
        setIsOrdered(true)
        console.log(`data: ${data}`)
        setOrderId(data[0].id)
      }
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }

  return (
    <main role="main">
      <div className="container-fluid inner-page">
        {" "}
        <h1 className="banner-text">PRODUCT VIEW</h1>
      </div>
      <div className="container page-top-2">
        <div className="row padding30">
          <div className="col-sm-5">
            <img className="img-fluid" src={`http://localhost:8000/storage/product/picture/${image}`} alt={image ? name : 'No Photo'} />

          </div>
          <div className="col-sm-7">
            <h1>
              Product Name : <span className="color-one">{name}</span>{" "}
            </h1>
            <h2>
              Product Price :{price}
            </h2>
            <h2>
              URL for Reference :{url}
            </h2>
            <h2>Description : </h2>
            <p className="color-one lead">
              {description}
            </p>
            {
              !isOrdered && (
                <Button
                  className="btn btn-primary"
                  onClick={(event) => {
                    createOrder(event)
                  }}
                >
                  BUY NOW
                </Button>
              ) || (
                <Button
                  className="btn btn-primary"
                  onClick={(event) => {
                    cancelOrder(event)
                  }}
                >
                  Return Product
                </Button>
              )
            }
          </div>
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
      </div>
      { }

    </main>
  );
}

