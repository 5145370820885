import $5 from '../../images/5.jpg';
import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';

export default function ViewAdvertisment() {
  const navigate = useNavigate();

  const { id } = useParams()
  console.log(id)
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [url, setUrl] = useState("")
  const [image, setImage] = useState()
  const [validationError, setValidationError] = useState({})

  useEffect(() => {
    fetchAdvertisment()
  }, [])

  const fetchAdvertisment = async () => {
    await axios.get(`http://localhost:8000/api/advertisments/${id}`).then(({ data }) => {
      console.log(data.advertisment)
      const { name, description, url, picture } = data.advertisment
      setName(name)
      setDescription(description)
      setUrl(url)
      setImage(picture)
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }

  const changeHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const updateAdvertisment = async (e) => {
    e.preventDefault();

    const formData = new FormData()
    formData.append('_method', 'PATCH');
    formData.append('name', name)
    formData.append('description', description)
    formData.append('url', url)
    if (image !== null) {
      formData.append('picture', image)
    }

    await axios.post(`http://localhost:8000/api/advertisments/${id}`, formData).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      })
      navigate("/")
    }).catch(({ response }) => {
      if (response.status === 422) {
        setValidationError(response.data.errors)
      } else {
        Swal.fire({
          text: response.data.message,
          icon: "error"
        })
      }
    })
  }

  return (
    <main role="main">
      <div className="container-fluid inner-page">
        {" "}
        <h1 className="banner-text">ADVERTISEMENT VIEW</h1>
      </div>
      <div className="container page-top-2">
        <div className="row padding30">

          <div className="col-sm-5">
            <img className="img-fluid" src={`http://localhost:8000/storage/advertisment/picture/${image}`} alt={image ? name : 'No Photo'} />

          </div>
          <div className="col-sm-7">
            <h1>
              Title : <span className="color-one">{name}</span>{" "}
            </h1>
            <h2>
              URL :{url}
            </h2>
            <h2>Description : </h2>
            <p className="color-one lead">
              {description}
            </p>
            { }
          </div>
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
      </div>
      { }

    </main>
  );

}

