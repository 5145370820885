import React from "react";
import { Link } from "react-router-dom";

import $1 from '../../images/1.png';
import $2 from '../../images/2.png';
import $3 from '../../images/3.png';
class Dashboard extends React.Component {
  render() {
    return (
      <main role="main">
        { }
        { }
        <div className="container marketing padding30">
          { }
          <div className="row">
            <div className="col-lg-4">
              { }
              <img
                src={$1}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Products</h2>
              <p>
                As a business owner on the college, you can post all the
                products of your business, sell them online, delete them anytime
                required.{" "}
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/products"}>
                  Manage Products »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$2}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Advertisements</h2>
              <p>
                As a business owner you can post the advertisements related to
                your business products to extend your horizon throughout the
                college.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/advertisments"}>
                  Manage Advertisements »
                </Link>
              </p>
            </div>
            { }
            <div className="col-lg-4">
              { }
              <img
                src={$3}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Profile</h2>
              <p>
                As a business owner, you can maintain a profile of your details
                that is visible to others when they search.
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/profile"}>
                  Profile »
                </Link>
              </p>
            </div>
            { }
          </div>
          { }
          <div className="row">
            <div className="col-lg-4">
              { }
              <img
                src={$1}
                alt=""
                className="bd-placeholder-img rounded-circle"
                width={140}
                height={140}
              />
              <h2>Chat</h2>
              <p>
                As a business owner, you can chat with the students and respond
                to their queries whenever required
              </p>
              <p>
                <Link className="btn btn-secondary" to={"/clubs"}>
                  Chat »
                </Link>
              </p>
            </div>
            { }
          </div>
          { }
          { }
          { }
        </div>
        { }
        { }

      </main>
    );
  }
}

export default Dashboard;
