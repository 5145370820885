import $5 from '../../images/5.jpg';
import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';

export default function ViewClub() {
  const navigate = useNavigate();

  const { id } = useParams()
  console.log(id)
  const [isJoined, setIsJoined] = useState(false)
  const [joinId, setJoinId] = useState("")
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [url, setUrl] = useState("")
  const [image, setImage] = useState()

  useEffect(() => {
    fetchClub()
  }, [])

  const fetchClub = async () => {
    await axios.get(`http://localhost:8000/api/clubs/${id}`).then(({ data }) => {
      console.log(data.club)
      const { name, description, url, picture } = data.club
      setName(name)
      setDescription(description)
      setUrl(url)
      setImage(picture)
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
    await axios.get(`http://localhost:8000/api/joins?club_id=${id}&student_id=${JSON.parse(sessionStorage.currentUser).id}`).then(({ data }) => {
      if (data.length > 0) {
        setIsJoined(true)
        console.log(`data: ${data}`)
        setJoinId(data[0].id)
      }
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }

  const leaveClub = async () => {
    await axios.delete(`http://localhost:8000/api/joins/${joinId}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: 'Leaved Successfully'
      }).then(() => {
        window.location.reload();
      })
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  };

  const joinClub = async (e) => {

    const formData = new FormData()

    formData.append('club_id', id)
    formData.append('student_id', JSON.parse(sessionStorage.currentUser).id)
    await axios.post(`http://localhost:8000/api/joins`, formData).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: 'Joined Successfully'
      }).then(() => {
        window.location.reload();
      })
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }

  return (
    <main role="main">
      <div className="container-fluid inner-page">
        {" "}
        <h1 className="banner-text">ADVERTISEMENT VIEW</h1>
      </div>
      <div className="container page-top-2">
        <div className="row padding30">

          <div className="col-sm-5">
            <img className="img-fluid" src={`http://localhost:8000/storage/club/picture/${image}`} alt={image ? name : 'No Photo'} />

          </div>
          <div className="col-sm-7">
            <h1>
              Title : <span className="color-one">{name}</span>{" "}
            </h1>
            <h2>
              Reference :{url}
            </h2>
            <h2>Description : </h2>
            <p className="color-one lead">
              {description}
            </p>
            {
              !isJoined && (
                <Button
                  className="btn btn-primary"
                  onClick={(event) => {
                    joinClub(event)
                  }}
                >
                  JOIN NOW
                </Button>
              ) || (
                <Button
                  className="btn btn-primary"
                  onClick={(event) => {
                    leaveClub(event)
                  }}
                >
                  LEAVE NOW
                </Button>
              )
            }

          </div>
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
      </div>
      { }

    </main>
  );

}

