import { Link } from "react-router-dom"
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import $cart4 from '../../images/cart4.svg';
import Pagination from "react-js-pagination";
export default function ListProducts() {

  const [products, setProducts] = useState([])
  const [data, setData] = useState([])
  useEffect(() => {
    fetchProducts(1)
  }, [])

  const fetchProducts = async (page) => {
    await axios.get(`http://localhost:8000/api/products?page=${page}`).then(({ data }) => {
      setProducts(data.data)
      setData(data)
    })
  }

  const deleteProduct = async (id) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      return result.isConfirmed
    });

    if (!isConfirm) {
      return;
    }

    await axios.delete(`http://localhost:8000/api/products/${id}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      })
      fetchProducts()
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }
  const viewProduct = async (id) => {
    window.location.href = "/view/product/" + id;
  }
  const editProduct = async (id) => {
    window.location.href = "/edit/product/" + id;
  }

  return (
    <main role="main">
      <div className="container-fluid inner-page">
        {" "}
        <h1 className="banner-text">PRODUCTS</h1>
      </div>
      <div className="container page-top-2">
        <div className="row">
          <div className="col-sm-8">
            <h1 className="FL page-heading">PRODUCTS</h1>
          </div>
          <div className="col-sm-4">
            {" "}
            <Link
              className="btn btn-primary FR margin6"
              to={"/add/product"}
            >
              Add Product
            </Link>
            {/* <!-- <Link
              className="btn btn-primary FR margin6"
              to={"/delete/product"}
            >
              Patch Product
  </Link> --> */}
          </div>
        </div>
        <div className="row padding30">
          {
            products.length > 0 && (
              products.map((row, key) => (
                <div className="col-sm-3" key={key}>
                  <div className="card bdr-radius">
                    <img src={`http://localhost:8000/storage/product/picture/${row.picture}`} alt={row.picture ? row.name : 'No Photo'} />

                  </div>
                  <div className="product-title">
                    <Link to={"/view/product/1"}>{row.name}</Link>
                  </div>
                  <div className="price-cart">
                    <span className="prcing">${row.price}</span>
                    <span className="carting">
                      {" "}
                      <img src={$cart4} alt="" />
                    </span>
                  </div>
                  <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-primary btn-sm active">
                      <input type="radio" name="options" id="option1" onClick={() => viewProduct(row.id)} autocomplete="off" checked /> View
                    </label>
                    <label class="btn btn-info btn-sm">
                      <input type="radio" name="options" id="option2" onClick={() => editProduct(row.id)} autocomplete="off" /> Edit
                    </label>
                    <label class="btn btn-danger btn-sm">
                      <input type="radio" name="options" id="option3" onClick={() => deleteProduct(row.id)} autocomplete="off" /> Delete
                    </label>
                  </div>
                </div>
              )
              )
            )
          }
        </div>
        <div className="row">
          <nav
            aria-label="..."
            style={{
              margin: "0 auto"
            }}
          >
            <Pagination
              activePage={data.current_page ? data.current_page : 1}
              itemsCountPerPage={data.per_page ? data.per_page : 10}
              totalItemsCount={data.total ? data.total : 0}
              onChange={(pageNumber) => {
                fetchProducts(pageNumber)
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="«"
              lastPageText="»"
            />

          </nav>
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
      </div>
      { }

    </main>
  );
}
