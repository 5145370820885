
import $11 from '../../images/11.webp';
import $2 from '../../images/2.png';
import $cart4 from '../../images/cart4.svg';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Swal from 'sweetalert2'
import { post } from 'jquery';
import { useParams, Link } from 'react-router-dom'


export default function Profile() {

  const { profileId } = useParams()
  const [products, setProducts] = useState([])
  const [clubs, setClubs] = useState([])
  const [joins, setJoins] = useState([])
  const [posts, setPosts] = useState([])
  const [advertisments, setAdvertisments] = useState([])
  const [user, setUser] = useState([])

  useEffect(() => {
    fetchClubs(profileId)
    fetchPosts(profileId)
    fetchAdvertisments(profileId)
    fetchProducts(profileId)
    fetchUser(profileId)
  }, [])

  const fetchUser = async (id) => {
    await axios.get(`http://localhost:8000/api/users/${id}`).then(({ data }) => {
      setUser(data.user)
    })
  }
  const fetchProducts = async (id) => {
    await axios.get(`http://localhost:8000/api/products?created_by=${id}`).then(({ data }) => {
      setProducts(data)
    })
  }


  const fetchClubs = async (id) => {
    await axios.get(`http://localhost:8000/api/clubs/?created_by=${id}`).then(({ data }) => {
      setClubs(data)

    })
  }
  const fetchPosts = async (id) => {
    await axios.get(`http://localhost:8000/api/posts/?created_by=${id}`).then(({ data }) => {
      setPosts(data)

    })
  }
  const fetchAdvertisments = async (id) => {
    await axios.get(`http://localhost:8000/api/advertisments/?created_by=${id}`).then(({ data }) => {
      setAdvertisments(data)

    })
  }

  return (
    <main role="main">
      <div className="container-fluid inner-page">
        {" "}
        <h1 className="banner-text">PROFILE</h1>
      </div>
      <div className="container page-top-2">

        <div className="row">
          <div className="col-sm-8">
            <h1 className="FL page-heading">ADVERTISEMENTS</h1>
          </div>
          <div className="col-sm-4">
            {
              JSON.parse(sessionStorage.currentUser).id == profileId && (
                <Link
                  className="btn btn-primary FR margin6"
                  to={"/edit/profile"}
                >
                  Edit Profile
                </Link>
              )
            }
          </div>
        </div>
        <div className="row padding30">
          <div className="col-sm-3">
            <img src={`http://localhost:8000/storage/user/picture/${user.picture}`} className="rounded img-fluid" alt="..." />
            <br />
            <br />
            <h2 className="page-heading">{user.name}</h2>
            <p className="lead">
              {user.role}
              <br />
              <Link to={"mailto:" + user.email}>{user.email}</Link>
            </p>
          </div>{
            (clubs.length > 0 || posts.length > 0 || advertisments.length > 0 || products.length > 0) && (

              <div className="col-sm-8 offset-1">
                {clubs.length > 0 && (
                  <div>
                    <h2 className="page-heading">CLUB</h2>
                    <div className="row">
                      <div className="col-sm-3 ">
                        {
                          clubs.map((row, key) => (
                            <div className="col-sm-3  " key={key}>
                              <div className="card bdr-radius">
                                <img src={`http://localhost:8000/storage/club/picture/${row.picture}`} alt={row.picture ? row.name : 'No Photo'} />
                              </div>
                              <div className="club-title">
                                <Link to={"/view/club/" + row.id}>{row.name}</Link>
                              </div>
                            </div>
                          )
                          )
                        }
                      </div>

                    </div>
                  </div>)}  {advertisments.length > 0 && (
                    <div>
                      <h2 className="page-heading">Advertisements</h2>
                      <div className="row">
                        {
                          advertisments.length > 0 && (
                            advertisments.map((row, key) => (
                              <div className="col-sm-3 d-inline-block" key={key}>
                                <div className="card bdr-radius">
                                  <img src={`http://localhost:8000/storage/advertisment/picture/${row.picture}`} alt={row.picture ? row.name : 'No Photo'} />
                                </div>
                                <div className="club-title">
                                  <Link to={"/view/advertisment/" + row.id}>{row.name}</Link>
                                </div>
                              </div>
                            )
                            )
                          )
                        }

                      </div></div>
                  )
                }  {posts.length > 0 && (
                  <div>
                    <h2 className="page-heading">Posts</h2>
                    <div className="row">
                      <div className="col-sm-3 margin-bottom10 ">
                        {
                          posts.length > 0 && (
                            posts.map((row, key) => (
                              <div className="col-sm-3" key={key}>
                                <div className="card bdr-radius">
                                  <img src={`http://localhost:8000/storage/post/picture/${row.picture}`} alt={row.picture ? row.name : 'No Photo'} />
                                </div>
                                <div className="club-title">
                                  <Link to={"/view/post/" + row.id}>{row.name}</Link>
                                </div>
                              </div>
                            )
                            )
                          )
                        }

                      </div>
                    </div></div>
                )
                }
                {products.length > 0 && (
                  <div>
                    <h2 className="page-heading">Products</h2>
                    <div className="row">
                      <div className="col-sm-3 margin-bottom10 ">
                        {
                          products.length > 0 && (
                            products.map((row, key) => (
                              <div className="col-sm-3" key={key}>
                                <div className="card bdr-radius">
                                  <img src={`http://localhost:8000/storage/product/picture/${row.picture}`} alt={row.picture ? row.name : 'No Photo'} />
                                </div>
                                <div className="club-title">
                                  <Link to={"/view/product/" + row.id}>{row.name}</Link>
                                </div>
                                <div className="price-cart">
                                  <span className="prcing">${row.price}
                                  </span>

                                </div>
                              </div>
                            )
                            )
                          )
                        }

                      </div></div>


                  </div>)}
              </div>)
          }
        </div>
        <hr
          className="featurette-divider"
          style={{
            marginTop: "100px"
          }}
        />
      </div>
      { }

    </main>
  );
} 