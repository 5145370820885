import React, { Component, ReactNode, Fragment } from 'react';
import { Route, Router, Routes, Navigate } from 'react-router-dom';
import Home from '../components/static/Home';
import { Role } from '../services/role';
export const PrivateRoute = ({ component: Component, roles, ...props }) => {
    let currentUser = null;
    if (sessionStorage.currentUser) {
        currentUser = JSON.parse(sessionStorage.currentUser);
    }
    if (currentUser == null) {
        return <Navigate to={{ pathname: '/user/login', state: { from: props.location } }} />
    }
    if (roles && roles.indexOf(currentUser.role) === -1)
        return (<Navigate to="/403" />);

    return (
        <Component {...props} />
    );
}