import React, { useEffect, useState } from "react";
import axios from 'axios';
import Swal from 'sweetalert2';

import { useNavigate, useParams } from 'react-router-dom'
export default function Logout() {

    const navigate = useNavigate();
    if (sessionStorage.getItem("isUserLoggedIn")) {

        sessionStorage.setItem("isUserLoggedIn", false);
        sessionStorage.removeItem("currentUser")
        Swal.fire({
            icon: "success",
            text: "Logout Successful"
        }).then(function () {
            sessionStorage.setItem("logPath", "/user/login");
            sessionStorage.setItem("logPathName", "Login/ Register");
            window.location.href = "/";
        });
        return (
            <main role="main">
                <div className="container-fluid inner-page">
                    {" "}
                    <h1 className="banner-text">Logout Successful.</h1>
                </div>

            </main>
        );

    } else {

        return (
            <main role="main">
                <div className="container-fluid inner-page">
                    {" "}
                    <h1 className="banner-text">Already Logged Out.</h1>
                </div>

            </main>
        );
    }

}

